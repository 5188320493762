const fetchIndicators = () => {
  let waitingSecs = 0
  fetchData();

  function fetchData() {
    if(!document.querySelector(`[data-fetch-type]`)){
      return
    }

    fetch('/admin/dashboard/fetch_indicators_data')
      .then(response => response.json())
      .then(data => {
        if (!data.loading) {
          console.log("=====data received====")
          updateView(data);
        } else if(waitingSecs < 120) {
          console.log("still processing")
          const interval = 5
          waitingSecs += interval;
          setTimeout(fetchData, interval*1000); // Check again in 5 seconds
        }else{
          console.log("Too many fetches. STOPPING")
          replaceAllSpinners("❌")

        }
      })
      .catch(error => console.error('Error fetching data:', error));
  }

  function updateView(data) {
    // Assuming data contains keys like "repost_items", "auto_message_cycle", etc.
    console.log({data})
    for(const [dataType, dataTypeValues] of Object.entries(data)){
      if(dataTypeValues == null){continue}
      
      for(const [dataSubtype, value] of Object.entries(dataTypeValues)){
        let element = document.querySelector(`[data-fetch-type="${dataType}"] [data-fetch-subtype="${dataSubtype}"]`)
        if(element){element.textContent = value}
      }
    }

    replaceAllSpinners("/")
  }

  function replaceAllSpinners(replaceValue){
    document.querySelectorAll(".spinner-border").forEach((element) => {
      let parentNode = element.parentNode
      parentNode.innerHTML = replaceValue
    })
  }
}



export { fetchIndicators };